<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">

                <SettingMenu></SettingMenu>

                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{ $t(`pipeline setup`) }}
                            <v-popover offset="10" :disabled="false" style="display:initial;">
                                <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/manage-pipeline"
                                           target="_blank">
                                            {{ $t(`How to manage pipeline?`) }}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>
                        <a class="button primary-button text-capitalize" href="#" @click.prevent="showAddModal()">
                            <span class="icon"><i class="eicon e-plus"></i></span>
                            <span class="text-capitalize">{{ $t(`Add New Pipeline`) }}</span>
                        </a>
                    </div>
                    <!-- data table -->
                    <div class="data-table user-table" v-if="isContentLoading">
                        <TableLoader></TableLoader>
                    </div>
                    <div class="data-table user-table" v-else>
                        <h4 class="empty-message" v-show="isEmpty">{{ $t(`No pipeline found`) }}</h4>
                        <div class="table-wrap" v-show="!isEmpty">
                            <div class="table">
                                <div class="table__row table__head">
                                    <div class="table-cell text-center">{{ $t(`No`) }}</div>
                                    <div class="table-cell">{{ $t(`Name`) }}</div>
                                    <div class="table-cell">{{ $t(`Steps`) }}</div>
                                    <div class="table-cell text-center">{{ $t(`Actions`) }}</div>
                                </div>

                                <div class="table__row"
                                     v-for="(pipeline,index) in pipelines">
                                    <div class="table-cell pipeline-counter" v-text="parseInt(index)+1"></div>
                                    <div class="table-cell pipeline-name" v-text="pipeline.name"></div>
                                    <div class="table-cell pipeline-steps">
                                        <ul>
                                            <li v-for="step in pipeline.steps" v-text="step"></li>
                                            <li>{{ $t('Selected') }}</li>
                                            <li>{{$t('Rejected')}}</li>
                                        </ul>
                                    </div>
                                    <div class="table-cell pipeline-action">
                                        <div class="d-flex align-items-center justify-content-center" v-if="index!==0">
                                            <a href="javascript:void(0)" @click.prevent="editPipeline(index)"
                                               class="control-button control-button--primary has_bg_color">
                                                <div class="control-button__icon">
                                                    <i class="eicon e-pencil"></i>
                                                </div>
                                                <span>{{ $t(`Update`) }}</span>
                                            </a>
                                            <a href="javascript:void(0)" @click.prevent="deletePipeline(index-1)"
                                               class="control-button control-button--danger has_bg_color">
                                                <div class="control-button__icon">
                                                    <i class="eicon e-delete"></i>
                                                </div>
                                                <span>{{ $t(`Delete`) }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <modal-add-pipeline-template v-if="showAddPipeline" @closeAddModal="closeAddModal"
                                         @pipelineSaved="pipelineAdd"></modal-add-pipeline-template>
            <modal-edit-pipeline-template v-if="showEditPipeline" @closeUpdateModal="closeUpdateModal"
                                          @pipelineUpdated="pipelineUpdate"
                                          :pipeline="pipeline"></modal-edit-pipeline-template>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import ModalAddPipelineTemplate from "../../../components/modal/company/AddPipelineTemplate";
import ModalEditPipelineTemplate from "../../../components/modal/company/EditPipelineTemplate";
import client from "../../../app/api/Client";
import TableLoader from "../../../components/_loaders/_TableLoader";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
        ModalAddPipelineTemplate,
        ModalEditPipelineTemplate,
        TableLoader
    },
    data() {
        return {
            isContentLoading: false,
            pipelines: [],
            showAddPipeline: false,
            showEditPipeline: false,
            pipeline: []
        }
    },
    computed: {
        isEmpty() {
            return this.pipelines.length === 0;
        }
    },
    methods: {
        async getCompanyPipelines() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('company/setting/pipeline');
                this.pipelines = data;
            } catch (e) {
            }
            this.isContentLoading = false;

        },
        showAddModal() {
            this.showAddPipeline = true;
            const el = document.body;
            el.classList.add("modal-open");
        },
        editPipeline(index) {
            this.pipeline = {
                id: index,
                name: this.pipelines[index].name,
                steps: this.pipelines[index].steps
            };
            this.showEditPipeline = true;
            const el = document.body;
            el.classList.add('modal-open');
        },
        pipelineAdd(value) {
            this.pipelines.push(value.data);
            this.closeAddModal();
        },
        pipelineUpdate(value) {
            this.getCompanyPipelines();
            this.closeUpdateModal();
        },
        closeAddModal() {
            this.showAddPipeline = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },
        closeUpdateModal() {
            this.showEditPipeline = false;
            const el = document.body;
            el.classList.remove('modal-open');
        },
        deletePipeline(index) {
            let message = {
                title: this.$t('Confirmation'),
                body: this.$t("Are you sure, you want to delete this pipeline?")
            };
            this.$dialog.confirm(message).then(() => {
                client().delete(`company/setting/pipeline/${index}/delete`).then(({data: {message}}) => {
                    this.$toast.success(this.$t(message));
                   this.getCompanyPipelines();
                }).catch(({response: {data}}) => {
                    this.$toast.error(data.message);
                });
            });
        }
    },


    async created() {
        await this.getCompanyPipelines();
    }
}
</script>
