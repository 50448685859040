<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal fade custom-fields show" id="new-pipeline-template" style="display: block;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="close">
                    <div class="modal-header">
                        <div class="d-flex content-center">
                            <div>
                                <h4 class="modal-title text-uppercase" style="line-height:1.5;">{{
                                    $t("ADD PIPELINE")
                                    }}</h4>
                                <a href="https://easy.jobs/docs/manage-pipeline" target="_blank" class="form-note">
                                    <span class="custom-link">{{ $t(`How to manage pipeline?`) }}</span></a>
                            </div>

                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click.prevent="close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>{{ $t("Pipeline Name") }}*</label>
                            <input type="text" name="" id="pipeline_name" ref="pipeline_name"
                                   :class="{ 'has-error': errors.name.length }" v-model="form.name" class="form-control"
                                   :placeholder="$t('enter pipeline name')" autofocus/>
                            <div class="error-message" v-text="errors.name"></div>

                        </div>
                        <div class="form-group pipeline-step-list">
                            <label>{{ $t("Pipelines Steps") }}</label>
                            <div class="input-wrapper pipeline-wrapper" v-for="(step, index) in form.steps">
                                <input type="text" name="" class="form-control" :value="step"
                                       v-if="step==='Applied'  && index==0" style="background:#c6d3fd !important;"
                                       disabled/>
                                <input type="text" name="" class="form-control" :value="step"
                                       v-else disabled/>
                                <a href="#" class="input-wrapper-append" v-if="index!=0" @click="removeStep(index)">
                                    <i class="eicon e-delete"></i>
                                </a>
                            </div>
                            <div class="input-wrapper pipeline-wrapper">
                                <input type="text" name="" class="form-control" :value="$t('Selected')"
                                       style="background:#c6d3fd !important;" disabled/>
                            </div>
                            <div class="input-wrapper pipeline-wrapper">
                                <input type="text" name="" class="form-control" :value="$t('Rejected')"
                                       style="background:#c6d3fd !important;" disabled/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>{{ $t("Title") }}</label>
                            </div>
                            <div class="col-md-9">
                                <input type="text" name="" v-model.trim="newStep"
                                       v-bind:class="{ 'has-error': errors.step.length }" class="form-control"
                                       :placeholder="$t('New step title')"/>
                                <div class="error-message" v-text="errors.step"></div>

                            </div>
                            <div class="col-md-3">
                                <button class="button semi-button-info w-100" @click.prevent="addNewStep">
                                    {{ $t("Add New") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <button class="button semi-button-info" data-dismiss="modal" @click.prevent="close">
                            {{ $t('back') }}
                        </button>
                        <submit-button :click="savePipeline" :loading="isLoading">{{
                            $t("Save and Continue")
                            }}
                        </submit-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import client from "../../../app/api/Client";

export default {
    props: {},
    data() {
        return {
            form: {
                name: '',
                steps: {
                    0: 'Applied'
                }
            },
            errors: {
                name: '',
                step: ''
            },
            newStep: '',
            isLoading: false,
        }

    },
    methods: {
        removeStep(value) {
            this.form.steps = _.omit(this.form.steps, value);
        },
        savePipeline() {
            if (this.isValid()) {
                this.isLoading = true;
                client().post(`company/setting/pipeline`, this.form).then(({data: {data, message}}) => {
                    this.$toast.success(this.$t(message));
                    this.$emit('pipelineSaved', {data});
                }).catch(({response: {data}}) => {
                    this.$toast.error(this.$t(data.message));
                }).finally(res => {
                    this.isLoading = false;
                });
            }
        },
        isUniqueStep(newStep) {
            let steps = Object.values(this.form.steps);
            steps.push('selected');
            steps.push('rejected');
            let result = steps.find(step => step.trim().toLowerCase() === newStep.trim().toLowerCase());
            return !result;
        },
        addNewStep() {
            if (this.newStep == '') {
                this.errors.step = this.$t('Please write the step title');
            } else if (this.newStep.length < 4) {
                this.errors.step = this.$t('Step title is at least 4 characters');

            } else if (this.newStep != '' && this.newStep.length >= 4) {
                if (this.isUniqueStep(this.newStep)) {
                    this.form.steps[Date.now()] = this.newStep;
                    this.newStep = '';
                    this.errors.step = '';
                } else {
                    this.errors.step = this.$t('This step title already exists. Please enter a unique step title.');
                }
            } else {
                this.errors.step = this.$t('Please write the step name');
            }
        },
        close() {
            this.$emit('closeAddModal');
        },
        isValid() {
            if (this.form.name.length < 1) {
                this.errors.name = this.$t('Please write pipeline name');
                return false;
            }
            if (this.form.name.length < 3) {
                this.errors.name = this.$t('Pipeline name is at least 3 characters');
                return false;
            }
            return true;

        },
        reSet() {
            this.form.name = '';
            this.form.steps = ['applied'];
            this.errors.name = '';
            this.errors.step = '';
        }
    },
    mounted() {
        this.$refs.pipeline_name.focus();


    }

};
</script>
